import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyC6EijWr1_I7BNCAsgu0IyTEzCeUGKK0mk',
  authDomain: 'codex-newlife.firebaseapp.com',
  projectId: 'codex-newlife',
  storageBucket: 'codex-newlife.appspot.com',
  messagingSenderId: '615020590501',
  appId: '1:615020590501:web:675d1c0fcc501e84f1644c',
  measurementId: 'G-48N37EM62C',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
